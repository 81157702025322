import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Då var vi framme vid den 19 februari 2014 och vi har nedan laddat upp med alla dagens free spins erbjudande.`}</p>
    <p>{`###Bertil – 20 free spins
Hos Bertil så kan man idag få 20 free spins i mobilen igenom att spela för minst 100kr på något av följande spel för mobilen Starburst, Fruit Shop eller Flowers.`}</p>
    <p>{`20 free spins hos Bertil – Spela nu!`}</p>
    <p>{`###Betsson – Casino Store
Betsson Casino Store har kommit tillbaka och med den så får vi nya Casino erbjudande varje dag. Dagens erbjudande är. Spela 50 rundor i South Park och få sedan 20 free spins imorgon på South Park.
20 free spins på Betsson – Spela nu!`}</p>
    <p>{`###Vinnarum – Free spins och Casino bonus
Till den 20 februari 2014 så kan alla spelare hämta en insättnings bonus på 1000kr och 30 free spins i Big Bang hos Vinnarum. För att få ta del av dessa bonusar så behöver man göra en insättning på minst 500kr`}</p>
    <p>{`Hämta 1000kr i bonus och 30 free spins – Spela nu!`}</p>
    <p>{`###ComeOn
ComeOn kör vidare med samma kampanj som igår där man kan få upp till 80 free spins. För att få dessa free spins så behöver man spela något av följande spel Hot City, Immortal Romance, Jack och Jill eller Burning Desire. När man vinner 50kr så får man 10 free spins, vinner man 100kr så får man 20 free spins och vinner man 250kr så får man 50 free spins.`}</p>
    <p>{`80 free spins hos ComeOn – Spela nu!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      